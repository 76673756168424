import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    serviceProvider_title: 'Реестр услугодателей',
    serviceProvider_errorLoad: 'Не удалось загрузить данные услугодателя',
    serviceProvider_createForm: 'Создать',
    serviceProvider_saveForm: 'Сохранить',
    serviceProvider_saveFormSuccess: 'Данные успешно сохранены',
    serviceProvider_errorSaveForm: 'Не удалось сохранить данные',
    serviceProvider_id: 'Номер услугодателя',
    serviceProvider_iin: 'ИИН',
    serviceProvider_bin: 'БИН',
    serviceProvider_nedbId: 'ID НОБД',
    serviceProvider_parent: 'Родительская организация',
    serviceProvider_parentChoose: 'Выбрать',
    serviceProvider_latitude: 'Широта',
    serviceProvider_longitude: 'Долгота',
    serviceProvider_correctCoordinates: 'Укажите корректные координаты',
    serviceProvider_required: 'Обязательно для заполнения',
  },
  kk: {
    serviceProvider_title: 'Қызмет көрсетушілердің тізілімі',
    serviceProvider_errorLoad: 'Қызмет көрсетушінің деректерін жүктеу мүмкін емес',
    serviceProvider_createForm: 'Создать',
    serviceProvider_saveForm: 'Сақтау',
    serviceProvider_saveFormSuccess: 'Деректер сәтті сақталды',
    serviceProvider_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    serviceProvider_id: 'Қызметті көрсетушінің нөмірі',
    serviceProvider_iin: 'ЖСН',
    serviceProvider_bin: 'БСН',
    serviceProvider_nedbId: 'ID НОБД',
    serviceProvider_parent: 'Бас ұйым',
    serviceProvider_parentChoose: 'Таңдау',
    serviceProvider_latitude: 'Ендік',
    serviceProvider_longitude: 'Бойлық',
    serviceProvider_correctCoordinates: 'Дұрыс координаттарды көрсетіңіз',
    serviceProvider_required: 'Толтыру үшін міндетті',
  }
});

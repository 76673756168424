import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Row from '../../../passport/Components/Row';
import { Coordinates } from '../ServiceProviderStyle';
import InputMask from 'react-input-mask';
import FormHelperText from '@material-ui/core/FormHelperText';
import { validCoordinat } from '../ServiceProviderPage';

const MyMap = ({coordinates, setCoordinates}) => {
  const { t } = useTranslation();
  const [lat, setLat] = useState(coordinates.lat);
  const [lon, setLon] = useState(coordinates.lon);
  const [zoom, setZoom] = useState(7);
  const mapRef = useRef(null);

  useEffect(() => {
    setCoordinates({ lat, lon })
    if ((lat && String(lat).length >= 4) && (lon && String(lon).length >= 4)) {
      setZoom(17);
    } else {
      setZoom(7);
    }
  }, [lat, lon]);

  const handleClickMap = (e) => {
    const coords = e.get("coords"); // Получаем координаты клика
    setLat(coords[0].toPrecision(8));
    setLon(coords[1].toPrecision(8))
    setZoom(17);
  };

  return (
    <Box className='mt2'>
      <Coordinates>
        <Box style={{
          backgroundColor: 'rgba(125, 125, 125, 0.1)',
          padding: '10px 16px 14px',
          borderBottom: '1px solid #cecfd2'
        }}>
          <Typography children={'координаты'}/>
        </Box>
        <Box style={{
          padding: '10px 16px 14px',
        }}>
          <Row label={t('serviceProvider_latitude')+':'}>
            <>
              <InputMask
                mask="99.99999"
                maskChar=""
                value={lat}
                onChange={(e) => {
                  setLat(e.target.value);
                }}
              >
                {
                  () => <TextField
                    error={validCoordinat(lat) !== 'correctly'}
                    placeholder={t('serviceProvider_latitude')}
                  />
                }
              </InputMask>
              {
                validCoordinat(lat) !== 'correctly' &&
                <FormHelperText error={['required', 'noCorrectly'].includes(validCoordinat(lat))}>
                  {
                    validCoordinat(lat) === 'required' ? t('serviceProvider_required') : t('serviceProvider_correctCoordinates')
                  }
                </FormHelperText>
              }
            </>
          </Row>
          <Row label={t('serviceProvider_longitude')+':'}>
            <>
              <InputMask
                mask="99.99999"
                value={lon}
                maskChar=""
                onChange={(e) => setLon(e.target.value)}
              >
                {
                  () => <TextField
                    error={validCoordinat(lon) !== 'correctly'}
                    placeholder={t('serviceProvider_longitude')}
                  />
                }
              </InputMask>
              {
                validCoordinat(lon) !== 'correctly' &&
                <FormHelperText error={['required', 'noCorrectly'].includes(validCoordinat(lon))}>
                  {
                    validCoordinat(lon) === 'required' ? t('serviceProvider_required') : t('serviceProvider_correctCoordinates')
                  }
                </FormHelperText>
              }
            </>
          </Row>
          <Box className='mt2'>
            <YMaps>
              <Map
                state={{
                  center:[lat ? lat : 45.01783, lon? lon : 78.38212],
                  zoom: zoom,
                  controls: [],
                }}
                style={{
                  width: '100%',
                  height: '600px'
                }}
                instanceRef={mapRef}
                onClick={handleClickMap}
              >
                <Placemark geometry={[lat,lon]} />
              </Map>
            </YMaps>
          </Box>
        </Box>
      </Coordinates>
    </Box>
  );
};

export default MyMap;
